import React, { useState, useRef } from "react";
import { Chart } from "primereact/chart";
// import { Menu } from "primereact/menu";
import GenerateQRCode from "../services/QRCodeGenerator";
import TextInput from "../components/TextInput";
import { Toast } from "primereact/toast";

const Dashboard = (props) => {
    var [attendedAndNotAttended, setAttendedAndNotAttended] = useState([]);
    var [registration, setRegistration] = useState([]);
    var QRCodeGenerator = new GenerateQRCode();
    const toast = useRef(null);
    function formatOneDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    var [selectedEventDate, setSelectedEventDate] = useState(null);
    var [selectedRegDate, setSelectedRegDate] = useState(null);
    function viewParticipantList(e) {
        setSelectedEventDate(e.value);
        QRCodeGenerator.getAllStats({ "date": formatOneDate(e.value) }).then((res) => {
            console.log("Statistics", res);
            if (res?.message === "No data for the date selected.") {
                toast.current.show({ severity: "error", summary: "Error", detail: res?.message, life: 2500 });
            }
            setAttendedAndNotAttended(res);
        })
    }
    function viewRegistrationList(e) {
        setSelectedRegDate(e.value);
        QRCodeGenerator.getAllStats({ "date": formatOneDate(e.value) }).then((res) => {
            console.log("Statistics", res);
            if (res?.message === "No data for the date selected.") {
                toast.current.show({ severity: "error", summary: "Error", detail: res?.message, life: 2500 });
            }
            setRegistration(res);
        })
    }
    const getOrdersOptions = () => {
        const textColor = getComputedStyle(document.body).getPropertyValue("--text-color") || "rgba(0, 0, 0, 0.87)";
        const gridLinesColor = getComputedStyle(document.body).getPropertyValue("--divider-color") || "rgba(160, 167, 181, .3)";
        const fontFamily = getComputedStyle(document.body).getPropertyValue("--font-family");
        return {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        fontFamily,
                        color: textColor,
                    },
                },
            },
            scales: {
                y: {
                    ticks: {
                        fontFamily,
                        color: textColor,
                    },
                    grid: {
                        color: gridLinesColor,
                    },
                },
                x: {
                    ticks: {
                        fontFamily,
                        color: textColor,
                    },
                    grid: {
                        color: gridLinesColor,
                    },
                },
            },
        };
    };
    // const getOptions = () => {
    //     const textColor = getComputedStyle(document.body).getPropertyValue("--text-color") || "rgba(0, 0, 0, 0.87)";
    //     const gridLinesColor = getComputedStyle(document.body).getPropertyValue("--divider-color") || "rgba(160, 167, 181, .3)";
    //     const fontFamily = getComputedStyle(document.body).getPropertyValue("--font-family");
    //     return {
    //         indexAxis: "y",
    //         plugins: {
    //             legend: {
    //                 display: true,
    //                 labels: {
    //                     fontFamily,
    //                     color: textColor,
    //                 },
    //             },
    //         },
    //         scales: {
    //             y: {
    //                 ticks: {
    //                     fontFamily,
    //                     color: textColor,
    //                 },
    //                 grid: {
    //                     color: gridLinesColor,
    //                 },
    //             },
    //             x: {
    //                 ticks: {
    //                     fontFamily,
    //                     color: textColor,
    //                 },
    //                 grid: {
    //                     color: gridLinesColor,
    //                 },
    //             },
    //         },
    //     };
    // };
    let ordersOptions = getOrdersOptions();
    // let options = getOptions();

    // const getChartOptions = () => {
    //     const textColor = getComputedStyle(document.body).getPropertyValue("--text-color") || "rgba(0, 0, 0, 0.87)";
    //     // const gridLinesColor = getComputedStyle(document.body).getPropertyValue("--divider-color") || "rgba(160, 167, 181, .3)";
    //     const fontFamily = getComputedStyle(document.body).getPropertyValue("--font-family");
    //     return {
    //         plugins: {
    //             legend: {
    //                 display: true,
    //                 labels: {
    //                     fontFamily,
    //                     color: textColor,
    //                 },
    //             },
    //         },
    //     };
    // };
    // let chartOptions = getChartOptions();
    // const menu6 = useRef(null);

    const attendedAndNotAttendedPart = {
        labels: ["Attendance Overview"],
        datasets: [
            // {
            //     label: "Total Registrations",
            //     data: [attendedAndNotAttended?.total_registration],
            //     borderColor: "#00FFFF", // Example color, customize as needed
            //     backgroundColor: "#00FFFF",
            //     borderWidth: 2,
            //     fill: true,
            //     tension: 0.4,
            // },
            {
                label: "Attended",
                data: [attendedAndNotAttended?.total_attended],
                borderColor: "lightgreen", // Example color, customize as needed
                backgroundColor: "lightgreen",
                borderWidth: 2,
                fill: true,
                tension: 0.4,
            },
            {
                label: "Did not attend",
                data: [attendedAndNotAttended?.total_not_attended],
                borderColor: "#FF0000", // Example color, customize as needed
                backgroundColor: "#FF0000",
                borderWidth: 2,
                fill: true,
                tension: 0.4,
            },
        ],
    };
    const registrationPerDay = {
        labels: ["Attendance Overview"],
        datasets: [
            {
                label: "Total Registrations",
                data: [registration?.total_registration],
                borderColor: "#00FFFF", // Example color, customize as needed
                backgroundColor: "#00FFFF",
                borderWidth: 2,
                fill: true,
                tension: 0.4,
            },
        ],
    };

    // useEffect(() => {
    //     QRCodeGenerator.getAllStats().then((res) => {
    //         // var attendedAndNotAttended = [];
    //         // attendedAndNotAttended.push(res?.total_registration);
    //         // attendedAndNotAttended.push(res?.total_attended);
    //         // attendedAndNotAttended.push(res?.total_not_attended);
    //         // console.log("Statistics", res);
    //         setAttendedAndNotAttended(res);
    //     })

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (

        <div className="grid dashboard">
            <Toast ref={toast} />
            <div className="col-12 lg:col-6">
                <div className="card height-100">
                    <div className="col-12  lg:col-6">
                        <TextInput value={selectedEventDate} onChange={(e) => viewParticipantList(e)} type="Calendar" label={<>Select event date <span style={{ color: "red" }}>*</span></>} />
                    </div>
                    <div style={{ visibility: 'hidden' }}>SPACE</div>
                    <div className="card-header">
                        <h5>Attendance per day {selectedEventDate ? `(${formatOneDate(selectedEventDate)})` : null}</h5>
                    </div>
                    <Chart type="bar" data={attendedAndNotAttendedPart} options={ordersOptions}></Chart>
                </div>
            </div>
            <div className="col-12 lg:col-6">
                <div className="card height-100">
                    <div className="col-12  lg:col-6">
                        <TextInput value={selectedRegDate} onChange={(e) => viewRegistrationList(e)} type="Calendar" label={<>Select event date <span style={{ color: "red" }}>*</span></>} />
                    </div>
                    <div style={{ visibility: 'hidden' }}>SPACE</div>
                    <div className="card-header">
                        <h5>Registration per day {selectedRegDate ? `(${formatOneDate(selectedRegDate)})` : null}</h5>
                    </div>
                    <Chart type="bar" data={registrationPerDay} options={ordersOptions}></Chart>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode) && (prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded) && (prevProps.onNewThemeChange === nextProps.onNewThemeChange);
};

export default React.memo(Dashboard, comparisonFn);
