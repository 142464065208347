import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// import { useHistory } from "react-router-dom";
// import LoginService from "../services/loginService";
import { Toast } from "primereact/toast";
import GenerateQRCode from "../services/QRCodeGenerator";
import { Password } from 'primereact/password';
// import axios from "axios";
// import { Dialog } from "primereact/dialog";
// import LoginServices from "../services/LoginService";


// import LangConfig from "../Lang/LangConfig";

const Login = () => {

	// var lang = LangConfig()
	// var loginService = new LoginServices();
	var QRCodeGenerator = new GenerateQRCode();

	var [loginDetails, setLoginDetails] = useState({
		email: "",
		password: "",
	});
	// const [dialogShow, setDialogShow] = useState(false);
	// const [load, setLoad] = useState(false);
	const toast = useRef(null);
	// var loginServices = new LoginService();
	async function submitLoginDetails() {
		// await loginServices.getUserIp().then((res) => {
		// 	console.log(4444, res?.IPv4);
		// 	console.log(4444, res);
		// 	loginDetails.Terminal = res?.IPv4;
		// });
		var loginArray = {};
		Object.keys(loginDetails).map((key) => {
			loginArray[key] = loginDetails[key];
			return null;
		});
		// var error = false;
		Object.keys(loginArray).map((key) => {
			var value = loginArray[key];
			if (value === "") {
				// error = true;
			}
			return null;
		});
		console.log(loginArray);
		await QRCodeGenerator.loginCode(loginArray).then((res) => {
			console.log("Data", res);
			if (res?.success === false) {
				return toast.current.show({ severity: "error", summary: "Login Failed", detail: res?.message, life: 5000 });
			}
			setTimeout(() => {
				if (res?.idToken) {
					console.log("The token is present");
					localStorage.setItem("isAuthenticated", true);
					localStorage.setItem("token", JSON.stringify(res?.idToken));
					window.location.replace("/")
				}
			}, 2000);
			return toast.current.show({ severity: "success", summary: "Success", detail: "User is authenticated successfully", life: 2500 });
		})
			.catch((e) => {
				console.log(e?.response?.data);
				return toast.current.show({ severity: "warn", summary: "Warning", detail: e?.response?.data, life: 5000 });
			});
	}

	return (
		<div className="pages-body login-page flex flex-column">
			<Toast ref={toast} />
			{/* <Dialog closable={false} visible={dialogShow} style={{ width: "10%", height: "10%" }} draggable={false}>
				<div></div>
				<div>
					<DataTable loading={load}></DataTable>
				</div>
			</Dialog> */}
			<div className="align-self-center mt-auto mb-auto">
				<div className="pages-panel card flex flex-column">
					<div className="pages-header px-3 py-1">
						<h2>Login</h2>
					</div>
					<h4>Welcome</h4>
					<div className="input-panel flex flex-column px-3">
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon">
								<i className="pi pi-envelope"></i>
							</span>
							<span className="p-float-label">
								<InputText type="text" id="inputgroup1" onChange={(e) => setLoginDetails({ ...loginDetails, email: e.target.value })} />
								<label htmlFor="inputgroup1">Email</label>
							</span>
						</div>

						<div className="p-inputgroup mt-3 mb-6">
							<span className="p-inputgroup-addon">
								<i className="pi pi-lock"></i>
							</span>
							<span className="p-float-label">
								<Password type="password" toggleMask id="inputgroup2" onChange={(e) => setLoginDetails({ ...loginDetails, password: e.target.value })} />
								<label htmlFor="inputgroup2">Password</label>
							</span>
						</div>
					</div>
					<Button
						className="button-mark-attendance"
						label="Login"
						onClick={submitLoginDetails}
						style={{
							backgroundColor: '#2e1a47',
							color: 'white', // You might want to adjust the text color based on your design
						}}
						disabled={loginDetails?.username === "" || loginDetails?.password === ""}
					/>

				</div>
			</div>
		</div>
	);
};

export default Login;
