/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import TextInput from "../components/TextInput";
import CSVReader from 'react-csv-reader';
import GenerateQRCode from "../services/QRCodeGenerator";
import { NET_IP } from "../config/Config";
import CalendarInput from "../components/CalendarInput";
import { ProgressBar } from 'primereact/progressbar';
import * as XLSX from 'xlsx';




const Participant = (props) => {
    var [selectedParticipant, setSelectedParticipant] = useState(null);
    const [load, setLoad] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loaderDialog, setLoaderDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previousParticipantsData, setPreviousParticipantsData] = useState([]);
    const [participantsData, setParticipantsData] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const csvReaderRef = useRef(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    //const [participantsDataBE, setParticipantsDataBE] = useState([]);
    const [participantsDataDB, setParticipantsDataDB] = useState([]);
    var [selectedStartDate, setSelectedStartDate] = useState(null);
    var [selectedEndDate, setSelectedEndDate] = useState(null);
    var [selectedEventDate, setSelectedEventDate] = useState(null);


    var QRCodeGenerator = new GenerateQRCode();

    function formatOneDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }


    function viewParticipantList(e) {
        setLoader(true);
        setSelectedEventDate(e.value)
        console.log({ "date": formatOneDate(e.value) });
        QRCodeGenerator.getAllParticipants(({ "date": formatOneDate(e.value) })).then((response) => {
            console.log("Members Data:", response);
            if (response?.message === "No participants found for the provided date") {
                toast.current.show({ severity: "error", summary: "Error", detail: response?.message, life: 2500 });
            }
            let data = [];
            if (
                Array.isArray(response.name) &&
                Array.isArray(response.surname) &&
                Array.isArray(response.affiliation) &&
                Array.isArray(response.title) &&
                Array.isArray(response.qr_code_base64) &&
                Array.isArray(response.attended) &&
                Array.isArray(response.designation) &&
                Array.isArray(response.email) &&
                Array.isArray(response.event_role) &&
                response.name.length === response.surname.length &&
                response.name.length === response.affiliation.length &&
                response.name.length === response.title.length &&
                response.name.length === response.qr_code_base64.length &&
                response.name.length === response.designation.length &&
                response.name.length === response.email.length &&
                response.name.length === response.event_role.length &&
                response.name.length === response.attended.length
            ) {
                for (let i = 0; i < response.name.length; i++) {
                    data.push({
                        name: response.name[i],
                        surname: response.surname[i],
                        affiliation: response.affiliation[i],
                        title: response.title[i],
                        qr_code_base64: response.qr_code_base64[i],
                        attended: response.attended[i],
                        designation: response.designation[i],
                        email: response.email[i],
                        event_role: response.event_role[i]
                    });
                }
            }
            setParticipantsDataDB(data);
            setLoader(false);
        }).catch((error) => {
            console.error("Error fetching participants:", error);
            // Handle the error, e.g., show an error message to the user
        });
    }

    function reportDownload() {
        const formattedData = participantsDataDB.map(({ event_role, qr_code_base64, ...rest }) => rest);

        // Get the unique keys (column names) from the first object in the array
        const columnNames = Object.keys(participantsDataDB[0]);

        // Ensure 'Title' is the first column and exclude 'event_role' and 'qr_code_base64'
        const excludedColumns = ['event_role', 'qr_code_base64'];
        const columnOrder = ['title', ...columnNames.filter(col => col !== 'title' && !excludedColumns.includes(col))];

        // Create a new worksheet with the rearranged column order
        const wsData = formattedData.map(item => {
            const rowData = columnOrder.map(col => item[col] || '');
            return Object.values(rowData);
        });

        const ws = XLSX.utils.aoa_to_sheet([columnOrder.map(col => col.toUpperCase()), ...wsData]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'Attendance_report.xlsx');

    }

    // useEffect(() => {
    //     QRCodeGenerator.getAllParticipants('2024-02-01').then((response) => {
    //         console.log("Members Data:", response);
    //         let data = [];

    //         if (
    //             Array.isArray(response.name) &&
    //             Array.isArray(response.surname) &&
    //             Array.isArray(response.affiliation) &&
    //             Array.isArray(response.title) &&
    //             Array.isArray(response.qr_code_base64) &&
    //             Array.isArray(response.attended) &&
    //             response.name.length === response.surname.length &&
    //             response.name.length === response.affiliation.length &&
    //             response.name.length === response.title.length &&
    //             response.name.length === response.qr_code_base64.length &&
    //             response.name.length === response.attended.length
    //         ) {
    //             for (let i = 0; i < response.name.length; i++) {
    //                 data.push({
    //                     name: response.name[i],
    //                     surname: response.surname[i],
    //                     affiliation: response.affiliation[i],
    //                     title: response.title[i],
    //                     qr_code_base64: response.qr_code_base64[i],
    //                     attended: response.attended[i]
    //                 });
    //             }
    //         }
    //         setParticipantsDataDB(data);
    //     }).catch((error) => {
    //         console.error("Error fetching participants:", error);
    //         // Handle the error, e.g., show an error message to the user
    //     });
    // }, [participantsDataBE]);

    const handleFile = async (data) => {
        // Skip the first 2 rows
        const headerRow = data.slice(2);
        // console.log(headerRow);
        setParticipantsData(headerRow);
    }

    const toast = useRef(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1["global"].value = value;
        setFilters(_filters1);
        setGlobalFilterValue(value);
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Participants</h5>
        </div>
    );
    var [form, setForm] = useState({
        title: "",
        name: "",
        surname: "",
        affiliation: "",
        document_ids: "",
        email: ""
    });
    var [form2] = useState({
        title: "",
        name: "",
        surname: "",
        affiliation: "",
        id: "",
        email: "",
        documentID: "",
    });
    var [dataToPrint] = useState({
        title: "",
        name: "",
        surname: "",
        affiliation: "",
        qr_code_base64: "",
    });

    function printBadge() {
        dataToPrint.title = selectedParticipant?.title;
        dataToPrint.name = selectedParticipant?.name;
        dataToPrint.surname = selectedParticipant?.surname;
        dataToPrint.affiliation = selectedParticipant?.affiliation;
        dataToPrint.qr_code_base64 = selectedParticipant?.qr_code_base64;
        var printingArray = {};
        Object.keys(dataToPrint).map((key) => {
            printingArray[key] = dataToPrint[key];
            return null;
        });

        console.log("Printing details", printingArray);
        fetch(`${NET_IP}/download_png`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(printingArray)
        })
            .then(response => {
                console.log(response)
                // Check if the response is successful
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                // Return the response body as a Blob
                return response.blob();
            })
            .then(blob => {
                // Create a URL for the Blob object
                const url = URL.createObjectURL(blob);
                // Open a new tab/window with the PNG content
                window.open(url, '_blank');
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    }
    function submitParticipant() {
        setLoaderDialog(true);
        setLoading(true);
        var newForm = {};
        form.document_ids = formatDate(findDatesInRange());
        Object.keys(form).forEach((key) => {
            newForm[key] = form[key];
        });

        Object.keys(newForm).forEach((key) => {
            var value = newForm[key];
            if (value === "") {
                toast.current.show({ severity: "error", summary: "Error", detail: "Please fill in all the fields", life: 2500 });
            }
        });
        console.log(newForm);
        QRCodeGenerator.createPartcipant(newForm).then((response) => {
            console.log("RESPONSE", response);
            setLoaderDialog(false);
            setLoading(false);
            window.location.reload();
            return toast.current.show({ severity: "success", summary: "Participant Added", detail: "Participant added successfully", life: 2500 });
        });

    }
    function formatDate(dates) {
        return dates.map(date => {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        });
    }
    const findDatesInRange = () => {
        const datesInBetween = [];
        let currentDate = new Date(selectedStartDate);

        while (currentDate < selectedEndDate) {
            datesInBetween.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Push the selectedEndDate only if it's not already included
        if (!datesInBetween.some(date => date.getTime() === selectedEndDate.getTime())) {
            datesInBetween.push(new Date(selectedEndDate));
        }

        return datesInBetween;

    };

    function submitCSV() {
        console.log(formatDate(findDatesInRange()))
        setLoad(true)
        // Assuming participantsData is the original data structure
        // console.log("participantsData", participantsData);
        // Function to clean a string by removing slashes
        const cleanString = (value) => {
            if (value !== undefined && value !== null && value.trim() !== '') {
                return value.replace(/[\\]/g, '');
            }
            // If value is undefined, null, or an empty string, return undefined
            return undefined;
        };

        // Transform the data into the desired JSON structure and clean the strings
        const transformedData = {
            "json_data": participantsData
                .map(entry => ({
                    "Title": cleanString(entry[0]),
                    "Name": cleanString(entry[1]),
                    "Surname": cleanString(entry[2]),
                    "Email": cleanString(entry[3]),
                    "Affiliation": cleanString(entry[4]),
                    "Designation": cleanString(entry[5]),
                    "EventRole": cleanString(entry[6])
                }))
                .filter(entry => Object.values(entry).every(value => value !== undefined)),
            "dates": formatDate(findDatesInRange())
        };

        console.log("Transformed Data", transformedData);
        // QRCodeGenerator.generateQRCode(transformedData)
        //     .then((response) => {
        //         console.log("RESPONSE", response);
        //         //setParticipantsDataBE(response?.qr_data);
        //         toast.current.show({
        //             severity: "success",
        //             summary: "Participants Loaded",
        //             detail: "Participants loaded successfully",
        //             life: 2500
        //         });
        //         setLoad(false);
        //         setShowAddDialog(false);
        //         setTimeout(() => {
        //             window.location.reload();
        //         }, 3000);
        //     })
        //     .catch((error) => {
        //         console.error("Error generating QR code:", error);
        //         toast.current.show({
        //             severity: "error",
        //             summary: "Error",
        //             detail: "There was an error while uploading the CSV file",
        //             life: 3000
        //         });
        //         setLoad(false);
        //     });
    }
    function SubmitEditForm() {
        form2.title = selectedParticipant?.title;
        form2.name = selectedParticipant?.name;
        form2.surname = selectedParticipant?.surname;
        form2.affiliation = selectedParticipant?.affiliation;
        form2.id = previousParticipantsData;
        form2.email = selectedParticipant?.email;
        form2.documentID = formatOneDate(selectedEventDate);
        var newForm = {};
        Object.keys(form2).forEach((key) => {
            newForm[key] = form2[key];
        });
        Object.keys(newForm).forEach((key) => {
            var value = newForm[key];
            if (value === "") {

            }
        });
        console.log(newForm);
        QRCodeGenerator.editParticipantsDetails(newForm).then((response) => {
            console.log("RESPONSE", response);
            window.location.reload();
            return toast.current.show({ severity: "success", summary: "Participant Updated", detail: "Participant updated successfully", life: 2500 });
        });
    }
    function membersDetails() {
        return [
            {
                name: "Title",
                value: selectedParticipant && selectedParticipant?.title,
            },
            {
                name: "Name",
                value: selectedParticipant && selectedParticipant?.name,
            },
            {
                name: "Surname",
                value: selectedParticipant && selectedParticipant?.surname,
            },
            {
                name: "Affiliation",
                value: selectedParticipant && selectedParticipant?.affiliation,
            },
            {
                name: "Email",
                value: selectedParticipant && selectedParticipant?.email,
            },
            {
                name: "Date",
                value: formatOneDate(selectedEventDate),
            },
            {
                name: "QR Code",
                value: selectedParticipant && (
                    <img src={`data:image/png;base64, ${selectedParticipant.qr_code_base64}`} alt="QR Code" style={{ width: '100px', height: '100px' }} />
                ),
            },
            {
                name: "Attended",
                value: selectedParticipant && selectedParticipant?.attended,
            },
        ];
    }

    return (
        <div className="card  p-align-stretch vertical-container">
            <Toast ref={toast} />
            <div className="">
                <Toolbar
                    className="mb-4"
                    left={
                        <>
                            <div>
                                <Button style={{
                                    color: 'white', // You might want to adjust the text color based on your design
                                }} className="mr-2" icon="pi pi-plus" label="Add Participants" onClick={(e) => setShowAddDialog(true)} />
                            </div>
                        </>
                    }
                    right={
                        <div className="col-12 lg:col-12">
                            <TextInput icon={"pi pi-search"} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="🔍 Search..." />
                        </div>
                    }
                ></Toolbar>
            </div>
            <div className="col-12  lg:col-3">
                <TextInput type="Calendar" value={selectedEventDate} onChange={(e) => viewParticipantList(e)} label={<>Select event date <span style={{ color: "red" }}>*</span></>} />
                <div style={{ visibility: 'hidden' }}>SPACE</div>
                <Button disabled={participantsDataDB?.length === 0} label="Download a report" className="p-button" icon="pi pi-download" onClick={reportDownload} type="submit" />
                <div style={{ visibility: 'hidden' }}>SPACE</div>
            </div>

            <Dialog
                draggable={false}
                header={<span>{`Participants Details -  ${selectedParticipant && `${selectedParticipant?.title} ${selectedParticipant?.name} ${selectedParticipant?.surname}`}`}</span>}
                style={{ width: "90%", height: "90%" }}
                modal
                visible={showDialog}
                onHide={(e) => {
                    setShowDialog(false);
                }}
            >
                <TabView>
                    <TabPanel header="Participant Details">
                        <div style={{ position: 'relative' }}>
                            <DataTable size="small" scrollable={true} value={membersDetails()} dataKey="id" responsiveLayout="scroll" resizableColumns>
                                <Column style={{ width: "200px" }} body={(e) => <b>{e.name}</b>}></Column>
                                <Column body={(e) => e.value}></Column>
                            </DataTable>
                            {/* <Button label="Generate a Badge" className="p-button" icon="pi pi-print" onClick={printBadge} type="submit" style={{ position: 'absolute', top: '400px', right: '10px' }} /> */}
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>
            <Dialog closable={false} visible={loaderDialog} style={{ width: "10%", height: "10%" }} draggable={false}>
                <div></div>
                <div>
                    <DataTable loading={loading}></DataTable>
                </div>
            </Dialog>
            <Dialog
                draggable={false}
                header={<span>{`Edit Participant - ${selectedParticipant && `${selectedParticipant?.title} ${selectedParticipant?.name} ${selectedParticipant?.surname}`}`}</span>}
                style={{ width: "90%", height: "90%" }}
                footer={
                    <>
                        <Button label="Update Participant Details" onClick={SubmitEditForm} className="p-button" type="submit" />
                    </>
                }
                modal
                visible={showEditDialog}
                onHide={(e) => {
                    setShowEditDialog(false);
                }}
            >
                <TabView>
                    <TabPanel header="Edit Participant">
                        <div className="grid">
                            <div className="col-12  lg:col-4">
                                <TextInput label="Title" value={selectedParticipant && selectedParticipant?.title} onChange={(e) => setSelectedParticipant({ ...selectedParticipant, title: e.target.value })} />
                            </div>
                            <div className="col-12  lg:col-4">
                                <TextInput label="Name" value={selectedParticipant && selectedParticipant?.name} onChange={(e) => setSelectedParticipant({ ...selectedParticipant, name: e.target.value })} />
                            </div>
                            <div className="col-12 lg:col-4">
                                <TextInput label="Surname" value={selectedParticipant && selectedParticipant?.surname} onChange={(e) => setSelectedParticipant({ ...selectedParticipant, surname: e.target.value })} />
                            </div>
                            <div className="col-12 lg:col-4">
                                <TextInput label="Affiliation" value={selectedParticipant && selectedParticipant?.affiliation} onChange={(e) => setSelectedParticipant({ ...selectedParticipant, affiliation: e.target.value })} />
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>
            <Dialog
                draggable={false}
                header={
                    <>
                        <i className="pi pi-plus"></i>
                        <span> Add Participants</span>
                    </>
                }
                style={{ width: "90%", height: "90%" }}
                modal
                visible={showAddDialog}
                onHide={(e) => {
                    setShowAddDialog(false);
                }}
            >
                <TabView>
                    <TabPanel header="Add a Participant">
                        <div className="grid">
                            <div className="col-12 lg:col-3">
                                <CalendarInput type="Calendar" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.value)} label={<>Start date <span style={{ color: "red" }}>*</span></>} />
                            </div>
                            <div className="col-12 lg:col-3">
                                <CalendarInput type="Calendar" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.value)} label={<>End date <span style={{ color: "red" }}>*</span></>} />
                            </div>
                        </div>
                        <div style={{ visibility: 'hidden' }}>SPACE</div>
                        <div>
                            <div className="grid">
                                <div className="col-12 lg:col-3">
                                    <TextInput label={<>TITLE <span style={{ color: "red" }}>*</span></>} value={form.title} onChange={(e) => setForm({ ...form, title: e.target.value })} />
                                </div>
                                <div className="col-12  lg:col-3">
                                    <TextInput label={<>NAME <span style={{ color: "red" }}>*</span></>} value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
                                </div>
                                <div className="col-12  lg:col-3">
                                    <TextInput label={<>SURNAME <span style={{ color: "red" }}>*</span></>} value={form.surname} onChange={(e) => setForm({ ...form, surname: e.target.value })} />
                                </div>
                                <div className="col-12  lg:col-3">
                                    <TextInput label={<>AFFILIATION <span style={{ color: "red" }}>*</span></>} value={form.affiliation} onChange={(e) => setForm({ ...form, affiliation: e.target.value })} />
                                </div>
                                <div className="col-12  lg:col-3">
                                    <TextInput label={<>EMAIL <span style={{ color: "red" }}>*</span></>} value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div style={{ visibility: 'hidden' }}>SPACE</div>
                        <div className="footer">
                            <Button
                                style={{
                                    color: 'white',
                                    float: 'right',
                                }}
                                disabled={form.title === "" || form.name === "" || form.surname === "" || form.affiliation === "" || selectedStartDate === null || selectedEndDate === null}
                                onClick={submitParticipant}
                                label="Submit"
                            />
                        </div>
                    </TabPanel>
                    <TabPanel header="Upload Participants">
                        <div className="p-align-stretch vertical-container">
                            <div>
                                <label>File <span style={{ color: "red" }}>*</span></label>
                                <CSVReader
                                    inputStyle={{ display: "none" }}
                                    ref={csvReaderRef} onFileLoaded={(data, fileInfo, originalFile) => handleFile(data, fileInfo, originalFile)} />
                                <Button
                                    label="Upload a CSV file"
                                    className="mr-2"
                                    onClick={(e) => {
                                        if (csvReaderRef.current) {
                                            csvReaderRef.current.value = '';
                                            csvReaderRef.current.click();
                                        }
                                    }}
                                />
                                <div style={{ visibility: 'hidden' }}>SPACE</div>
                                <div className="grid">
                                    <div className="col-12  lg:col-3">
                                        <CalendarInput type="Calendar" value={selectedStartDate} onChange={(e) => setSelectedStartDate(e.value)} label={<>Start date <span style={{ color: "red" }}>*</span></>} />
                                    </div>
                                    <div className="col-12  lg:col-3">
                                        <CalendarInput type="Calendar" value={selectedEndDate} onChange={(e) => setSelectedEndDate(e.value)} label={<>End date <span style={{ color: "red" }}>*</span></>} />
                                    </div>
                                </div>
                                {/* <div style={{ visibility: 'hidden' }}>SPACE</div>
                                <label>File <span style={{ color: "red" }}>*</span></label>
                                <FileUpload disabled={uploadButtonDisabled} name="demo[]" url="./upload" mode="advanced" customUpload uploadHandler={handleUpload}></FileUpload> */}
                                <div style={{ visibility: 'hidden' }}>SPACE</div>
                                {load && <ProgressBar mode="indeterminate" style={{ height: '6px' }} />}
                                <DataTable
                                    responsiveLayout="stack"
                                    breakpoint="960px"
                                    size="small"
                                    scrollable={true}
                                    dataKey="id"
                                    paginator
                                    value={participantsData}
                                    rows={4}
                                    className="datatable-responsive"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                                    emptyMessage="No participants found."
                                    header={header}
                                    loading={load}
                                    selection={selectedParticipant}
                                    onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                    resizableColumns
                                    filters={filters}
                                    filterDisplay="Contact"
                                    globalFilterFields={["Contact"]}
                                    columnResizeMode="expand"
                                >

                                    <Column field="Title" header="Title" body={(e) => e && e[0]}></Column>
                                    <Column field="Name" header="Name" body={(e) => e && e[1]}></Column>
                                    <Column field="Surname" header="Surname" body={(e) => e && e[2]}></Column>
                                    <Column style={{ whiteSpace: 'normal' }} field="Contact" header="Email" body={(e) => e && e[3]}></Column>
                                    <Column style={{ whiteSpace: 'normal' }} field="Organisation" header="Affiliation" body={(e) => e && e[5]}></Column>
                                    <Column style={{ whiteSpace: 'normal' }} field="Designation" header="Designation" body={(e) => e && e[6]}></Column>
                                    <Column style={{ whiteSpace: 'normal' }} field="EventRole" header="Event Role" body={(e) => e && e[7]}></Column>
                                </DataTable>
                            </div>
                            <div style={{ visibility: 'hidden' }}>SPACE</div>
                            <div className="footer">
                                <Button
                                    disabled={participantsData.length === 0 || selectedStartDate === null || selectedEndDate === null}
                                    style={{
                                        color: 'white',
                                        float: 'right',
                                    }}
                                    onClick={submitCSV}
                                    label="Submit"
                                />
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </Dialog>
            <DataTable
                responsiveLayout="stack"
                breakpoint="960px"
                size="small"
                value={participantsDataDB}
                scrollable={true}
                dataKey="id"
                paginator
                loading={loader}
                rows={5}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                emptyMessage="No participants found."
                header={header}
                selection={selectedParticipant}
                onSelectionChange={(e) => setSelectedParticipant(e.value)}
                resizableColumns
                filters={filters}
                filterDisplay="name"
                globalFilterFields={["name", "surname", "affiliation"]}
                columnResizeMode="expand"
            >
                <Column style={{ width: '100px' }} field="title" header="Title" body={(e) => e?.title}></Column>
                <Column style={{ width: '100px' }} sortable field="name" header="Name" body={(e) => e?.name}></Column>
                <Column style={{ width: '100px' }} field="surname" header="Surname" body={(e) => e?.surname}></Column>
                <Column style={{ whiteSpace: 'normal' }} field="affiliation" header="Affiliation" body={(e) => e?.affiliation}></Column>
                <Column style={{
                    overflow: 'visible', // Set overflow to visible to prevent hidden content
                }} field="email" header="Email" body={(e) => e?.email}></Column>
                <Column style={{ width: '100px' }} field="qr_code_base64" header="QR Code" body={(rowData) => <img src={`data:image/png;base64, ${rowData.qr_code_base64}`} alt="QR Code" style={{ width: '70px', height: '70px' }} />} />
                <Column style={{ width: '100px' }} field="attended" header="Attended" body={(e) => e?.attended}></Column>
                <Column
                    field="actions"
                    header="Actions"
                    body={(e) => (
                        <>
                            <Button
                                disabled={e?.attended === "Yes"}
                                style={{ textAlign: "center", width: "30px", height: "30px" }}
                                icon={"pi pi-pencil"}
                                tooltipOptions={{ position: "top" }}
                                className="p-button-primary p-button-rounded mr-2"
                                tooltip="Click to Edit"
                                onClick={(a) => {
                                    setSelectedParticipant(e);
                                    setShowEditDialog(true);
                                    setPreviousParticipantsData(e.title.replace(/\s/g, '') + e.name.replace(/\s/g, '') + e.surname.replace(/\s/g, '') + e.affiliation.replace(/\s/g, ''));
                                }}
                            />
                            <Button
                                style={{ textAlign: "center", width: "30px", height: "30px" }}
                                icon={"pi pi-qrcode"}
                                tooltipOptions={{ position: "top" }}
                                className="p-button-primary p-button-rounded mr-2"
                                tooltip="Click to view the participant"
                                onClick={(a) => {
                                    setShowDialog(true);
                                    setSelectedParticipant(e);
                                }}
                            />
                        </>
                    )}
                ></Column>
            </DataTable>
        </div >
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};

export default React.memo(Participant, comparisonFn);
