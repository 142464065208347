import React, { useState, useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import GenerateQRCode from "../services/QRCodeGenerator";
import { Toast } from "primereact/toast";
import './QrCodeScanner.css';


const QRCodeScanner = () => {
    const [scanResult, setScanResult] = useState(null);
    const toast = useRef(null);
    var QRCodeGenerator = new GenerateQRCode();
    const handleScanSuccess = (result) => {
        setScanResult(result);
    };

    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5,
        });

        scanner.render(handleScanSuccess);

        return () => {
            // Cleanup when the component is unmounted
            if (scanner.clear) {
                scanner.clear();
            }
            // Check if the stop method is available before calling it
            if (scanner._reader && scanner._reader.stop) {
                scanner._reader.stop();
            }
        };
    }, []);

    

    function formatOneDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    const handleSendButtonClick = () => {
        const jsonData = {
            qrdata: scanResult,
            documentID: formatOneDate(new Date()),
        };
        const jsonString = JSON.stringify(jsonData);
        // Now you can send the jsonString as needed, for example, using an API request.
        console.log(jsonString);
        QRCodeGenerator.markAttendance(jsonString).then((response) => {
            if (response.status === "error") {
                // Show toast message
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Attendance could not be marked",
                    life: 1500
                });
                return;
            }
            console.log("RESPONSE", response);
            // Show toast message
            toast.current.show({
                severity: "success",
                summary: "Participant Added",
                detail: "Attendance marked successfully",
                life: 1500
            });
            // Delay the page reload to allow time for the toast to be displayed
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        });
    };
    return (
        <div>
            <Toast ref={toast} />
            <div className='camera-container'>
                {scanResult
                    ? <div className="success-message"><p className='result-message'>Participant: {scanResult}</p></div>
                    : <div id="reader" className="reader-style" ></div>
                }
            </div>
            {/* Conditionally render the button only if scanResult is not null */}
            {scanResult && (
                <div className="button-container">
                    <button onClick={handleSendButtonClick} className="button-mark-attendance">Mark Attendance</button>
                </div>
            )}
        </div>
    );
};
export default QRCodeScanner;
