import React, { useRef } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Chart } from "primereact/chart";
import { Menu } from "primereact/menu";

const Error = () => {
  const history = useHistory();
  const menu6 = useRef(null);
  const goDashboard = () => {
    history.push("/");
  };

  return (
    <div className="pages-body error-page flex flex-column">
      <div className="topbar p-3 flex justify-content-between flex-row align-items-center">
        <div className="topbar-left ml-3 flex">
          <div className="logo">
            <img src="assets/layout/images/logo2x.png" alt="" />
          </div>
        </div>
        <div className="col-24 lg:col-6">
          <div className="card height-100">
            <div className="card-header">
              <h5>Test</h5>
              <div>
                <Button
                  type="button"
                  icon="pi pi-ellipsis-h"
                  className="p-button-rounded p-button-text p-button-plain"
                  onClick={(event) => menu6.current.toggle(event)}
                ></Button>
                <Menu
                  ref={menu6}
                  popup
                  model={[
                    {
                      label: "Select Dates",
                      icon: "pi pi-fw pi-calendar",
                      command: () => {},
                    },
                  ]}
                ></Menu>
              </div>
            </div>
            <Chart type="bar" data={[]} options={[]}></Chart>
          </div>
        </div>
        <div className="topbar-right mr-3 flex">
          <Button
            type="button"
            onClick={goDashboard}
            label="DASHBOARD"
            className="p-button-text p-button-plain topbar-button"
          ></Button>
        </div>
      </div>

      <div className="align-self-center mt-auto mb-auto">
        <div className="pages-panel card flex flex-column">
          <div className="pages-header px-3 py-1">
            <h2>ERROR</h2>
          </div>
          <div className="card mt-3 px-6">
            <img src="assets/layout/images/pages/error.png" alt="" />
          </div>
          <div className="pages-detail pb-6">
            Requested resource is not available.
          </div>
          <Button
            onClick={goDashboard}
            type="button"
            label="GO BACK TO DASHBOARD"
            className="p-button-text"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default Error;
