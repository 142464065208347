import axios from "axios";
import { NET_IP } from "../config/Config";

export default function GenerateQRCode() {
  this.loginCode = (data) => {
    var url = `${NET_IP}/login`;
    return axios.post(url, data).then((response) => response.data);
  };
  this.generateQRCode = (data) => {
    var url = `${NET_IP}/generate_qr`;
    return axios.post(url, data).then((response) => response.data);
  };
  this.createPartcipant = (data) => {
    var url = `${NET_IP}/create_qr`;
    return axios.post(url, data).then((response) => response.data);
  };
  this.markAttendance = (data) => {
    var url = `${NET_IP}/markAttendance`;
    return axios.post(url, data).then((response) => response.data);
  };
  this.editParticipantsDetails = (data) => {
    var url = `${NET_IP}/editParticipants`;
    return axios.post(url, data).then((response) => response.data);
  };
  this.getAllParticipants = (date) => {
    var url = `${NET_IP}/participants`;
    return axios.post(url, date).then((response) => response.data);
  };
  this.getAllStats = (date) => {
    var url = `${NET_IP}/attendanceStats`;
    return axios.post(url, date).then((response) => response.data);
  };
  this.generatePDF = (data) => {
    var url = `${NET_IP}/generate_png`;
    return axios.post(url, data).then((response) => response.data);
  };
}
